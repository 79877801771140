import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import "@fontsource/poppins";
import '@fontsource/poppins/300.css';
import "@fontsource/poppins/400.css";
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import "@fontsource/poppins/400-italic.css"

import New from '../../components/rides/new/New';
import BottomNav from '../../components/common/BottomNav.js';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
})

function RidesNew() {
  return (
    <ThemeProvider theme={theme}>
      <New />
      <BottomNav />
    </ThemeProvider>
  );
}

export default RidesNew;
