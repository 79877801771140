import dayjs from "dayjs";
import Bar from './Bar';
import List from './List';
import Loader from './Loader';
import './Search.css';
import React, { useState } from "react";
import { Box, Container, Stack, Typography } from '@mui/material';
import bannerImg from './assets/banner.jpg';
import initStateImg from './assets/initState.png'
import notFoundStateImg from './assets/notFoundState.png'
import Nav from "../../common/Nav";
import { DEFAULT_TIMEZONE } from '../../../config';

const INITIAL_PROMPT_TEXT = 'Selecciona tu origen, destino y fecha para ver los viajes disponibles';
const NOT_FOUND_PROMPT_TEXT = 'Lo sentimos, no encontramos viajes para la fecha seleccionada. Prueba con otra fecha o destino';
const ERROR_PROMPT_TEXT = 'Algo salió mal. Por favor, intenta de nuevo más tarde.';

const NONE_REQUEST_STATUS = 'none';
const LOADING_REQUEST_STATUS = 'loading';
const LOADED_REQUEST_STATUS = 'loaded';
const ERROR_REQUEST_STATUS = 'error';

function buildPrompt (reqStatus) {
  let text = INITIAL_PROMPT_TEXT;
  if (reqStatus === LOADED_REQUEST_STATUS) {
    text = NOT_FOUND_PROMPT_TEXT;
  } else if (reqStatus === ERROR_REQUEST_STATUS) {
    text = ERROR_PROMPT_TEXT;
  }

  const imgSrc =
    reqStatus === NONE_REQUEST_STATUS ? initStateImg : notFoundStateImg;

  return (
    <Stack sx={{mt: 2}} alignItems="center">
      <Typography fontSize={18} textAlign="center" color="grey">
        {text}
      </Typography>
      <Box
        component="img"
        src={imgSrc}
        sx={{width: 350, mt: 6}}
      />
    </Stack>
  );
}

function Rides() {
  const [locationFrom, setLocationFrom] = useState({});
  const [locationTo, setLocationTo] = useState({});
  const [date, setDate] = useState(dayjs().tz(DEFAULT_TIMEZONE));
  const [rides, setRides] = useState([]);
  const [requestStatus, setRequestStatus] = useState(NONE_REQUEST_STATUS);

  const fetchRides = async () => {
    if (!locationFrom.geo || !locationTo.geo) {
      return;
    }

    setRequestStatus(LOADING_REQUEST_STATUS);

    var query = `from_lat=${locationFrom.geo.lat}&from_lng=${locationFrom.geo.lng}&`
    query += `to_lat=${locationTo.geo.lat}&to_lng=${locationTo.geo.lng}&`
    query += `departure_date=${date.format('DD-MM-YYYY')}`

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/rides?${query}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok');
      })
      .then((result) => {
        setRides(result.data);
        setRequestStatus(LOADED_REQUEST_STATUS);
      }).catch((_) => {
        setRequestStatus(ERROR_REQUEST_STATUS);
      });
  };

  const swapRoute = () => {
    const from = locationFrom
    const to = locationTo

    setLocationTo(from)
    setLocationFrom(to)
  }

  let content = buildPrompt(NONE_REQUEST_STATUS);

  if (requestStatus === LOADING_REQUEST_STATUS) {
    content = <Loader />;
  } else if (requestStatus === LOADED_REQUEST_STATUS && rides.length > 0) {
    content = <List rides={rides} />;
  } else {
    content = buildPrompt(requestStatus);
  }

  return (
    <Stack
      direction='column'
      alignContent="center"
      alignItems="stretch"
      className="Search"
    >
        <Nav />
        <Box className="RidesTop">
          <Container sx={{pb: 2, pt: {xs: 8, sm: 0} }}>
            <Box
              component="img"
              src={bannerImg}
              sx={{width: 400, display: { xs: 'none', sm: 'block' }}}
            />
            <Bar
              date={date}
              locationFrom={locationFrom}
              locationTo={locationTo}
              setLocationFrom={setLocationFrom}
              setLocationTo={setLocationTo}
              setDate={setDate}
              onSearch={fetchRides}
              onRouteSwap={swapRoute}
              disabled={requestStatus === LOADING_REQUEST_STATUS}
            />
          </Container>
        </Box>
        <Container maxWidth="md" sx={{mt: 3}} className="RidesContent">
          {content}
        </Container>
    </Stack>
  );
}

export default Rides;
