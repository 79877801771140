import { Button, IconButton, styled } from '@mui/material';
import { green, grey } from '@mui/material/colors';

export const SearchButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[900]),
  backgroundColor: grey[900],
  '&:hover': {
      backgroundColor: grey[600],
  },
}));

export const PublishButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[900]),
  backgroundColor: grey[900],
  '&:hover': {
    backgroundColor: grey[600],
  },
}));

export const GreenIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  backgroundColor: green[500],
  '&:hover': {
      backgroundColor: green[700],
  },
}));
