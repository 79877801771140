import 'dayjs/locale/es';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import InputAdornment from '@mui/material/InputAdornment';
import Input from './Input';
import { SearchButton } from '../../common/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales';
import { Typography } from '@mui/material';
import { DEFAULT_TIMEZONE } from '../../../config';

const Bar = ({
  setDate,
  locationFrom,
  setLocationFrom,
  locationTo,
  setLocationTo,
  onRouteSwap,
  date,
  onSearch,
  disabled
}) => {
  const onDateChange = (e) => setDate(e);

  var divider =
    <Divider
      sx={{ height: 36, m: 0.5,  display: { xs: 'none', sm: 'block' }}}
      orientation="vertical"
    />

  return (
    <Paper
      component="form"
      elevation={3}
      sx={{ p: { xs: 2, sm: 1 }, display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}
    >
      <Input
        sx={{ ml: 2, flex: 1.2}}
        placeholder="Desde"
        setLocation={setLocationFrom}
        location={locationFrom}
        endAdornment={
          <InputAdornment position="start" sx={{ml: 1}}>
            <IconButton type="button" onClick={onRouteSwap}>
              <SwapHorizIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      {divider}
      <Input
        sx={{ml: 2, mr: 1, flex: 1}}
        placeholder="A"
        setLocation={setLocationTo}
        location={locationTo}
      />
      {divider}
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={'es-ES'}
        localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <DatePicker
          timezone={DEFAULT_TIMEZONE}
          className="DatePicker"
          value={date}
          format='DD MMMM'
          disablePast={true}
          onChange={onDateChange}
          sx={{p: 1, mr: 2, width: { sm:'150px' }}}
          slotProps={{textField: { variant: 'standard', InputProps: { disableUnderline: true }} }}
        />
      </LocalizationProvider>
      <SearchButton
        onClick={onSearch} 
        variant="contained"
        sx={{minWidth: 120, textTransform: 'none'}}
        disabled={disabled}
      >
        <Typography fontWeight={1000}>Buscar</Typography>
      </SearchButton>
    </Paper>
  );
};

export default Bar;
