import { Grid } from "@mui/material";
import Item from './Item';

const List = ({ rides }) => {
    return (
        <Grid container direction="column" justifyContent="center" spacing={2}>
            {rides.map((row) => (
                <Grid item key={row.attributes.id}>
                    <Item ride={row.attributes} />
                </Grid>
            ))}
        </Grid>
    );
}

export default List;
