import './Nav.css';
import { Box, Drawer, IconButton, Link, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from "@mui/material";
import { Link as ReactLink } from "react-router-dom";
import logoImg from './assets/logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

const linkTypographyStyle = {
  fontWeight: 500,
  fontSize: 15,
}

const navBoxStyle = {
  backgroundColor: 'white',
  pl: { xs: 1.8, sm: 2.5},
  pr: { xs: 2.2, sm: 3.5 },
}

const logoStyle = {
  height: {xs: 55, sm: 62},
  pt: { xs: 1, sm: 0 },
}

export default function Nav() {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const navigate = useNavigate();

  const DrawerList = (
    <Box sx={{ width: 150 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/")}>
            <ListItemText primary={
              <Typography sx={linkTypographyStyle}>
                Buscar viaje
              </Typography>
            } />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/rides/new")}>
            <ListItemText primary={
              <Typography sx={linkTypographyStyle}>
                Publicar viaje
              </Typography>
            } />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => window.open(process.env.REACT_APP_SUPPORT_URL, '_self')}>
            <ListItemText primary={
              <Typography sx={linkTypographyStyle}>
                Soporte
              </Typography>
            } />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

    return (
      <Box>
        <Drawer open={open} onClose={toggleDrawer(false)} anchor='right'>
          {DrawerList}
        </Drawer>

        <Box className="NavBox" sx={navBoxStyle}>
          <Stack direction="row" alignItems="center">
            <Link
                underline="none"
                href={process.env.REACT_APP_LANDING_URL}
                rel="noopener"
              >
                <Box
                  component="img"
                  src={logoImg}
                  sx={logoStyle}
                />
            </Link>

            {/* Mobile version */}
            <Stack
              className="Nav"
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{display: {xs: 'flex', sm: 'none'}}}
            >
              <IconButton onClick={toggleDrawer(true)} sx={{color: 'rgb(153, 153, 153)'}}>
                <MenuIcon />
              </IconButton>
            </Stack>

            {/* Web version */}
            <Stack
              className="Nav"
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{display: {xs: 'none', sm: 'flex'}}}
            >
              <Box sx={{mr: {sm: 3, xs: 1}}}>
                <ReactLink
                  to="/"
                >
                  <Typography sx={linkTypographyStyle}>
                    Buscar viaje
                  </Typography>
                </ReactLink>
              </Box>

              <Box sx={{mr: {sm: 3, xs: 1}}}>
                <ReactLink
                  to="/rides/new"
                >
                  <Typography sx={linkTypographyStyle}>
                    Publicar viaje
                  </Typography>
                </ReactLink>
              </Box>

              <Box sx={{mr: {sm: 3, xs: 1}}}>
                <Link
                  underline="none"
                  href={process.env.REACT_APP_SUPPORT_URL}
                  rel="noopener"
                >
                  <Typography sx={linkTypographyStyle}>
                    Soporte
                  </Typography>
                </Link>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>
    )
}
