import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import Tooltip from '@mui/material/Tooltip';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import PeopleIcon from '@mui/icons-material/People';
import { Box, CardActionArea, Chip, SvgIcon } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RouteIcon from '@mui/icons-material/Route';
import PlaceIcon from '@mui/icons-material/Place';
import { DEFAULT_TIMEZONE } from '../../../config';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

const Item = ({ ride }) => {
  const AURORIDES_COMMISSION_PERCENTAGE = 0.1;

  const departureAddr = ride.stops[0].human_address;
  const arrivalAddr = ride.stops[ride.stops.length - 1].human_address;
  const stops = ride.stops.slice(1, ride.stops.length - 1);
  const totalPrice = ride.price + Math.ceil(ride.price * AURORIDES_COMMISSION_PERCENTAGE);

  const start = dayjs(ride.departure_time);
  const end = dayjs(ride.arrival_time);
  const diff = dayjs.duration(end.diff(start)).format('HH[h] mm');

  const formatAddress = (addr) => addr.replace(', Venezuela', '');

  let currency = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: ride.currency
  });

  const horizTripLine =
    <Box className="TripLineHorizontal" sx={{mt: 10, display: {xs: 'none', sm: 'block'}}} />

  // Numbers of stops = stops - departure - arrival
  const stopsLabel = "Paradas: " + (ride.stops.length - 2);

  return (
    <Card className='RideItem' variant="outlined" sx={{':hover': {boxShadow: 4}}}>
      <CardActionArea href={ride.contact_url} target="_blank">
        <CardContent sx={{p: 3}}>

          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Stack direction={{sx: "column", sm: "row"}} spacing={2}>
              <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={2} alignContent="center" alignItems="center">
                  <Typography variant="body2" fontWeight="700" fontSize={18}>
                    {dayjs.utc(ride.departure_time).tz(DEFAULT_TIMEZONE).format('hh:mm A')}
                  </Typography>
                  {horizTripLine}
                  <Typography
                    sx={{width: 50, fontSize: 14, display: {xs: 'none', sm: 'block'}}}
                    gutterBottom
                    className="RideItem__SecondaryText"
                  >
                    {diff}
                  </Typography>
                  {horizTripLine}
                </Stack>
                <Typography variant="body2" sx={{maxWidth: '200px'}}>
                  {formatAddress(departureAddr)}
                </Typography>
              </Stack>
              {/* BEGIN: Mobile Only */}
              <Box className="TripLineVertical" sx={{mt: 1, display: {xs: 'block', sm: 'none'}}} />
              <Typography
                sx={{width: 50, fontSize: 14, mt: 1, mb: 1, display: {xs: 'block', sm: 'none'}}}
                className="RideItem__SecondaryText"
              >
                {diff}
              </Typography>
              <Box className="TripLineVertical" sx={{mb: 1, display: {xs: 'block', sm: 'none'}}} />
              {/* END: Mobile Only */}

              <Stack direction="column" spacing={1}>
                <Typography variant="body1" fontWeight="700" fontSize={18}>
                  {dayjs.utc(ride.arrival_time).tz(DEFAULT_TIMEZONE).format('hh:mm A')}
                </Typography>
                <Typography variant="body2">
                  {formatAddress(arrivalAddr)}
                </Typography>
              </Stack>
            </Stack>

            <Stack alignItems="center">
              <Typography className="RideItem__Price">
                {currency.format(totalPrice / 100)}
              </Typography>
              <Typography variant="caption" sx={{fontSize: 10}}>
                * por persona
              </Typography>
            </Stack>

          </Stack>

          <Chip
            icon={<RouteIcon />}
            label={stopsLabel}
            variant="outlined" sx={{mt: 2}}
          />

          {
            stops.length > 0 ?
              <Stack sx={{mt: 1}}>
                {stops.map((ride) => (
                  <Stack direction="row" key={ride.id} alignItems="center" sx={{mt: 1}}>
                    <PlaceIcon align='center' className="RideItem__StopIcon" sx={{mr: 0.5}} />
                    <Typography className="RideItem__Stop">{ride.human_address}</Typography>
                  </Stack>
                ))}
              </Stack> : ''
          }

          <Divider sx={{mb: 2, mt: 2}} />
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <Stack direction="row" spacing={1} alignItems="center">
              {
                ride.vehicle_type === "carro" ?
                <Tooltip title="Carro">
                  <SvgIcon className="RideItem__VehicleIcon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M16,6l3,4h2c1.11,0,2,0.89,2,2v3h-2c0,1.66-1.34,3-3,3s-3-1.34-3-3H9c0,1.66-1.34,3-3,3s-3-1.34-3-3H1v-3c0-1.11,0.89-2,2-2
                        l3-4H16 M10.5,7.5H6.75L4.86,10h5.64V7.5 M12,7.5V10h5.14l-1.89-2.5H12 M6,13.5c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5
                        s1.5-0.67,1.5-1.5S6.83,13.5,6,13.5 M18,13.5c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5S18.83,13.5,18,13.5z"
                      />
                    </svg>
                  </SvgIcon>
                </Tooltip>: ''
              }
              {
                ride.vehicle_type === "camioneta" ?
                <Tooltip title="Camioneta">
                  <SvgIcon className="RideItem__VehicleIcon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M16,6H10.5v4H1v5H3a3,3,0,0,0,6,0h6a3,3,0,0,0,6,0h2V12a2,2,0,0,0-2-2H19L16,6M12,7.5h3.5l2,2.5H12V7.5m-6,6A1.5,1.5,0,1,1,4.5,15,1.5,1.5,0,0,1,6,13.5m12,0A1.5,1.5,0,1,1,16.5,15,1.5,1.5,0,0,1,18,13.5Z"
                      />
                    </svg>
                  </SvgIcon>
                </Tooltip>: ''
              }
              {
                ride.vehicle_type === "van" ?
                <Tooltip title="Van">
                  <AirportShuttleIcon className="RideItem__VehicleIcon" />
                </Tooltip>: ''
              }
              {
                ride.vehicle_type === "bus" ?
                <Tooltip title="Bus">
                  <AirportShuttleIcon className="RideItem__VehicleIcon" />
                </Tooltip>: ''
              }
              <AccountCircleIcon className="RideItem__VehicleIcon" />
              <Typography gutterBottom align='center' className="RideItem__SecondaryText RideItem__DriverName">
                {ride.driver_first_name} {ride.driver_last_name}
              </Typography>
              <Divider sx={{ height: 20, m: 0.5,  display: { xs: 'none', sm: 'block' }}} orientation="vertical" />
              {
                ride.air_conditioning ?
                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                    <Tooltip title="AC">
                      <AcUnitIcon className="RideItem__SecondaryIcon" />
                    </Tooltip>
                    <Typography
                      gutterBottom
                      align='center'
                      className="RideItem__SecondaryText"
                      display={{xs:"none", sm: "block"}}
                    >
                    Aire acondicionado
                    </Typography>
                  </Stack>: ''
              }
              <Tooltip title="Asientos">
                <PeopleIcon className="RideItem__SecondaryIcon" />
              </Tooltip>
              <Typography align='center' className="RideItem__SecondaryText">
                {ride.pax_availability}
              </Typography>
              <Typography align='center' className="RideItem__SecondaryText" display={{xs:"none", sm: "block"}}>
                asientos disponibles
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default Item;
