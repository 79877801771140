import TripOrigin from "@mui/icons-material/TripOrigin";
import { InputAdornment, InputBase } from "@mui/material";
import LocationAutocomplete from '../../common/LocationAutocomplete';

const Input = ({
  sx,
  location,
  setLocation,
  placeholder,
  endAdornment
}) => {
  return (
      <LocationAutocomplete
        sx={sx}
        location={location}
        setLocation={setLocation}
        renderInput={(params) => {
          const {InputLabelProps, InputProps,...rest} = params;

          return (
            <InputBase
              {...params.InputProps}
              {...rest}
              sx={{mt: 1}}
              placeholder={placeholder}
              startAdornment={
                <InputAdornment position="start">
                    <TripOrigin fontSize="small" />
                </InputAdornment>
              }
              endAdornment={endAdornment}
            />
          )
        }}
      />
  );
}

export default Input;
