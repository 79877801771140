import { Autocomplete } from "@mui/material";
import { useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import googleAPIConfig from "../../googleAPIConfig";

const formatAddress = (addr) => addr.replace(", Venezuela", "");

const LocationAutocomplete = ({
  sx,
  location,
  setLocation,
  renderInput
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleOnInputChange = (_, newVal) => {
    if (newVal.trim() === '') {
      setLocation({});
    }

    setInputValue(newVal);
  }

  const fetchLocation = async (loc) => {
    if (!loc) {
      return;
    }

    placesService?.getDetails(
      { placeId: loc.place_id },
      (pd, _status) => {
        setLocation({
          place_id: loc.place_id,
          label: loc.label,
          geo: {
            lat: pd.geometry.location.lat(),
            lng: pd.geometry.location.lng(),
          }
        })
      }
    );
  }

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
  } = usePlacesService(googleAPIConfig);

  let places = placePredictions.map((p) => {
    return { label: formatAddress(p.description), place_id: p.place_id };
  })

  return (
      <Autocomplete
        freeSolo // Setting freeSolo mode prevents popup "no options" when user starts typing.
        disableClearable
        sx={sx}
        noOptionsText="No hemos encontrado este lugar"
        className="SearchInput"
        options={places}
        value={location}
        inputValue={inputValue}
        getOptionLabel={(opt) => { return opt.label || '' }}
        onKeyDown={(evt) => getPlacePredictions({ input: evt.target.value })}
        onChange={(_, val) => fetchLocation(val)}
        onInputChange={handleOnInputChange}
        isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
        renderInput={renderInput}
      />
  );
}

export default LocationAutocomplete;
