const googleAPIConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  inputAutocompleteValue: "country",
  options: {
    componentRestrictions: { country: 've' },
  },
  siteKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
}

export default googleAPIConfig;
