import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import SearchPage from '../components/rides/search/Search';
import "@fontsource/poppins";
import '@fontsource/poppins/300.css';
import "@fontsource/poppins/400.css";
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import "@fontsource/poppins/400-italic.css"
import BottomNav from '../components/common/BottomNav';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
})

function Root() {
  return (
    <ThemeProvider theme={theme}>
      <SearchPage />
      <BottomNav />
    </ThemeProvider>
  );
}

export default Root;
