import { Box, Skeleton } from "@mui/material";

const Loader = () => {
    return (
        <Box>
            <Skeleton variant="rectangular" height={150} animation="wave" sx={{mb: 2}}/>
            <Skeleton variant="rectangular" height={150} animation="wave" sx={{mb: 2}}/>
            <Skeleton variant="rectangular" height={150} animation="wave" sx={{mb: 2}}/>
            <Skeleton variant="rectangular" height={150} animation="wave" sx={{mb: 2}}/>
        </Box>
    )
}

export default Loader;
